'use strict';

/**
 * create and return breadcrumbs structured data schema
 */
function createBreadcrumbSchema() {
    var breadcrumbItems = [];
    $('ol.breadcrumb .breadcrumb-item').each(function(index) {
        var breadcrumb = {};
        var item = $(this);
        
        breadcrumb.position = index + 1;
        breadcrumb.name = item.text().trim();
        
        var link = item.find('a');
        if (link.length) {
            var itemUrl = link.attr('href');
            // If the URL is relative, prepend the base URL
            if (itemUrl && itemUrl.startsWith('/')) {
                itemUrl = window.location.origin + itemUrl;
            }
            breadcrumb.item = itemUrl;
        }
    
        breadcrumbItems.push({
            "@type": "ListItem",
            "position": breadcrumb.position,
            "name": breadcrumb.name,
            "item": breadcrumb.item
        });
    });

    if (breadcrumbItems.length) {
        var breadcrumbId = window.location.href + "#breadcrumb";

        var breadcrumbJsonLd = {
            "@type": "BreadcrumbList",
            "@id": breadcrumbId,
            "itemListElement": breadcrumbItems
        };
    }

    return breadcrumbJsonLd;
}

/**
 * create and return image schema for webpage structured data schema
 */
function generateWebPageImageSchema(primaryImageUrl) {
    var schema = {};
    var imageUrls = [];
    if (primaryImageUrl) {
        schema.primaryImageOfPage = {
            "@type": "ImageObject",
            "@id": primaryImageUrl
        }
        imageUrls.push(primaryImageUrl);
    }

    $('.schema-img').each(function() {
        var imgUrl = $(this).attr('src');
        if (imgUrl && !imageUrls.includes(imgUrl)) {
            imageUrls.push(imgUrl);
        }
    });
    $('.hero-media-desktop, .background-image-desktop:not(.slider .background-image-desktop)').each(function() {
        var imgUrl = $(this).attr('data-image');
        if (imgUrl && !imageUrls.includes(imgUrl) && imageUrls.length < 15) {
            imageUrls.push(imgUrl);
        }
    });
    if (imageUrls.length) {
        schema.image = imageUrls.map(function(url) {
            return { "@type": "ImageObject", "@id": url };
        });
    }
    return schema;
}

/**
 * update schema script
 */
function updateSchema(response) {
    try {
        var schemaScript = response ? response.find('script[type="application/ld+json"]') : $('script[type="application/ld+json"]');
        if (schemaScript.length) {
            var jsonData = JSON.parse(schemaScript.text());
            if (jsonData['@graph'] && jsonData['@graph'].length) {
                var imageUrl = $('[schema-header-img]').first().attr('schema-header-img');
                jsonData['@graph'].forEach(function(item) {
                    if (item['@type'] != 'WebPage' && !item.image && imageUrl) {
                        item.image = imageUrl;
                    } else if (item['@type'] == 'WebPage') {
                        var WebPageImageSchema = generateWebPageImageSchema(imageUrl);
                        if (WebPageImageSchema.image && WebPageImageSchema.image.length) {
                            item.image = WebPageImageSchema.image;
                        }
                        if (!item.primaryImageOfPage && WebPageImageSchema.primaryImageOfPage) {
                            item.primaryImageOfPage = WebPageImageSchema.primaryImageOfPage;
                        }
                        var breadcrumbSchema = createBreadcrumbSchema();
                        if (breadcrumbSchema) {
                            jsonData['@graph'].push(breadcrumbSchema);
                            item.breadcrumb = {
                                "@id": breadcrumbSchema["@id"]
                            };
                        }
                    }
                });
                var newJsonLdScript = $('<script type="application/ld+json">').text(JSON.stringify(jsonData));
                if ($('script[type="application/ld+json"]').length) {
                    $('script[type="application/ld+json"]').replaceWith(newJsonLdScript);
                } else {
                    $('head').append(newJsonLdScript);
                }
            }
        }
    } catch (error) {
        console.error('An error occurred:', error.message, 'Stack trace:', error.stack);
    }
}

module.exports = {
    createBreadcrumbSchema: createBreadcrumbSchema,
    generateWebPageImageSchema: generateWebPageImageSchema,
    updateSchema: updateSchema
};
