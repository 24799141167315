'use strict';

var base = require('./base');

module.exports = {
    pairedProductsSelectorToggle: function () {
        $('body').on('click touchstart', '.pdp-paired-products-buttons button', function (e) {
            var buttonSelected = $(this);
            var count = $('.pdp-paired-products-buttons button').index(this);

            $(".pdp-paired-products-buttons button").each(function () {
                $(this).removeClass('selected');
                $(this).attr('disabled', false);
            });

            buttonSelected.addClass('selected');
            buttonSelected.attr('disabled', true);

            if (buttonSelected.hasClass('no-pair')) {
                $('.slide-with-overlay').each(function () {
                    $(this).find('img').each(function () {
                        if ($(this) && $(this).hasClass('d-block')) {
                            $(this).removeClass('d-block');
                            $(this).addClass('d-none');
                        }
                    });
                });
            }

            var urlContent = $('.pdp-paired-products-buttons').attr('data-url');
            if (count === 1) {
                $('.pdp-paired-buttons-result').removeClass('d-none');

                // Clicks swatch so image overlay appears when paired option is selected
                var checkForButtons = setInterval(function () {
                    if ($('.pdp-paired-buttons-result .paired-buttons-result-list button').length) {
                        $('.pdp-paired-buttons-result .paired-buttons-result-list button')[0].click();
                        clearInterval(checkForButtons);
                    }
                }, 200);

                /**
                 * For edit modal
                 * Check if there is an existing paired product selected to be used as preselected
                 */
                if ($('.edit-modal-option').length > 0) {
                    var pairedDetails = $('.edit-modal-option');
                    if (pairedDetails.data('setid') != 'undefined' && pairedDetails.data('setid') != '') {
                        urlContent = urlContent + '&setid=' + pairedDetails.data('setid') + '&masterpairedid=' + pairedDetails.data('pid');
                    }
                }

                $.ajax({
                    url: urlContent,
                    type: 'get',
                    dataType: 'html',
                    success: function (response) {
                        $('.pdp-paired-button-values').html(response);
                        var priceHolder = $('.pdp-paired-button-values .btn.selected');
                        $('.prices .sales span').text(priceHolder.attr('data-priceformatted'));
                        $('.prices .sales span').attr('content', priceHolder.attr('data-pricedecimal'));
                        $('.product-detail-info .prices span.value').text(priceHolder.attr('data-priceformatted'));
                        $('.product-detail-info .prices span.value').attr('content', priceHolder.attr('data-pricedecimal'));

                        if ($('.pdp-paired-products-buttons').data('pricedisplay')) {
                            var strikethroughSnippet = `
                                <del>
                                    <div class="strike-through list">
                                        <span class="value" content="${priceHolder.attr('data-pricedecimal')}">${priceHolder.attr('data-priceformatted')}</span>
                                    </div>
                                </del>

                                <div class="sales">
                                    <span class="value" itemprop="priceCurrency" content="${$('.pdp-paired-products-buttons').data('currency')}">${$('.pdp-paired-products-buttons').data('currencysymbol')}</span><span itemprop="price" content="${$('.pdp-paired-products-buttons').data('pairedpricedecimal')}">${$('.pdp-paired-products-buttons').data('pairedpricedecimal')}</span>
                                </div>`
                            $('.prices .sales span').html(strikethroughSnippet);
                        }
                    }
                });
            } else {
                $('.pdp-paired-buttons-result').addClass('d-none');
                $('.pdp-paired-button-values').html('');

                var buttonValues = $('.pdp-paired-products-buttons');
                $('.prices .sales span').text(buttonValues.attr('data-priceformatted'));
                $('.prices .sales span').attr('content', buttonValues.attr('data-pricedecimal'));
                $('.product-detail-info .prices span.value').text(buttonValues.attr('data-priceformatted'));
                $('.product-detail-info .prices span.value').attr('content', buttonValues.attr('data-pricedecimal'));
            }
        });

        $('body').on('click touchstart', '.paired-buttons-result-list button', function (e) {
            var buttonSelected = $(this);

            $(".paired-buttons-result-list button").each(function () {
                $(this).removeClass('selected');
            });

            buttonSelected.addClass('selected');

            var priceHolder = $('.pdp-paired-button-values .btn.selected');
            if (!$('.pdp-paired-products-buttons').data('pricedisplay')) {
                $('.prices .sales span').text(priceHolder.attr('data-priceformatted'));
                $('.prices .sales span').attr('content', priceHolder.attr('data-pricedecimal'));
                $('.product-detail-info .prices span.value').text(priceHolder.attr('data-priceformatted'));
                $('.product-detail-info .prices span.value').attr('content', priceHolder.attr('data-pricedecimal'));
            }

            var overlayLargeUrl = buttonSelected.data('overlay-large');
            var overlaySmallUrl = buttonSelected.data('overlay-small');
            var $mainImg = $('.main-img');
            var $mainOverlayImg = $('.main-overlay-img');
            var $thumbnailMainImg = $('.thumbnail-main-img');
            var $thumbnailOverlayImg = $('.thumbnail-overlay-img');

            if ($mainImg.hasClass('d-none')) {
                $mainImg.removeClass('d-none');
                $mainImg.addClass('d-block');
            }

            if ($mainOverlayImg.hasClass('d-none')) {
                $mainOverlayImg.removeClass('d-none');
                $mainOverlayImg.addClass('d-block');
            }

            if ($thumbnailMainImg.hasClass('d-none')) {
                $thumbnailMainImg.removeClass('d-none');
                $thumbnailMainImg.addClass('d-block');
            }

            if ($thumbnailOverlayImg.hasClass('d-none')) {
                $thumbnailOverlayImg.removeClass('d-none');
                $thumbnailOverlayImg.addClass('d-block');
            }

            $mainOverlayImg.attr('src', overlayLargeUrl);
            $thumbnailOverlayImg.attr('src', overlaySmallUrl);
        });
    },
    pairedProductsInit: function () {
        base.pairedProductsInit
    },
    yotpoSubmit: function () {
        $('body').on('click', '.yotpo-submit', function (e) {
            var mcAnalyticsEnabled = $('.mcEnableAnalytics').data('sfmc-mcenableanalytics');
            if (mcAnalyticsEnabled && _etmc) {
                var email = $('#yotpo_input_question_email').val();
                var trackReview = false;
                //If question email is not present check for review email
                if (!email) {
                    email = $('.yotpo input[name="email"]').val();
                    trackReview = true;
                }
                if (email) {
                    $.ajax({
                        url: $('.sfmcMID').data('action'),
                        type: 'get',
                        data: { email: email },
                        success: function (data) {
                            if (mcAnalyticsEnabled && _etmc) {
                                var locale = $('body').data('locale');
                                var mcMID = $('.sfmcMID').data('sfmc-mid');
                                _etmc.push(["setOrgId", mcMID]);
                                _etmc.push(["setUserInfo", { "email": email, "email_sha256": data.hashedEmail, "details": { "locale": locale } }]);
                                if (trackReview) {
                                    var rating = $(".write-review-wrapper .review-star").filter("[aria-checked='true']").data('score');
                                    var pid = $("#yotpo-reviews-top-div").data('product-id');
                                    if (rating && pid) {
                                        _etmc.push(["trackRating", { "rating": rating, "item": pid, "unique_id": pid }]);
                                    }
                                }
                            }
                        }
                    });
                }
            }
        });
    },
    notifySubmit: function () {
        $('body').on('click touch', '.notify-submit-btn', function (e) {
            e.preventDefault();
            var inputValue = $('.oosNotifyMeEmail').val();
            var selectedPid= $('.oosNotifyMeEmail').attr("data-productId") == undefined ? $('.oosNotifyMeEmail').attr("data-notifyme-productid") : $('.oosNotifyMeEmail').attr("data-productId");
            var pattern = /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/;
            var isValidEmail = pattern.test(inputValue);
            if (isValidEmail) {
                var $form = $('.notify-submit-form');
                var url = $form.attr('action');
                var checked = false;
                if ($('#addToKlaviyoList')) {
                    checked = $('#addToKlaviyoList').is(':checked')
                }
                $.ajax({
                    url: url,
                    type: 'get',
                    data: { customerEmailID: inputValue,
                        selectedPid: selectedPid,
                        checked: checked
                    },
                    success: function (data) {
                        $('.notify-error-message').addClass('d-none');
                        $('.oosNotifyMeEmail').val('');
                        $('#notifyMeModal').modal('hide');
                        $('#notifyMeThankYouModal').modal('show');
                        $('#addToKlaviyoList').prop('checked', false);
                    },
                    error: function (data) {
                        $('.notify-me-content').remove();
                    }
                });
            } else {
                $('.notify-error-message').removeClass('d-none');
            }
        });
    },
    notifybackinstockKlaviyo: function () { // PAP-2017: Notify Me email form does not appear on combined variant/master PDPs when assigned at the variant level
        $("body").on('click', '.notify-bis', function (e) {
            window._klOnsite = window._klOnsite || [];
            window._klOnsite.push(['openForm', $(this).data('target')]);
        });
    },
    verifyProductIsAvailableOnPageLoad: function () {
        $(function () {
            var productData = document.querySelector('.product-data');
            var notifyMeContent = document.querySelector('#notify-me-section');

            if (!notifyMeContent) return;
            if (productData && productData.classList.contains('out-of-stock')) {
                notifyMeContent.classList.remove('d-none');
            }   else {
                notifyMeContent.classList.add('d-none');
            }
        });
    },
    notifyInputFieldSubmit: function () {
        $("body").on('keypress', '.oosNotifyMeEmail', function (e) {
            var keycode = (e.key ? e.key : e.which);
            if (keycode == 'Enter') {
                e.preventDefault();
                var inputValue = $('.oosNotifyMeEmail').val();
                var selectedPid= $('.oosNotifyMeEmail').attr("data-productId") == undefined ? $('.oosNotifyMeEmail').attr("data-notifyme-productid") : $('.oosNotifyMeEmail').attr("data-productId");
                var pattern = /^[\w.%+-]+@[\w.-]+\.[\w]{2,6}$/;
                var isValidEmail = pattern.test(inputValue);
                if (isValidEmail) {
                    var $form = $('.notify-submit-form');
                    var url = $form.attr('action');
                    var checked = false;
                    if ($('#addToKlaviyoList')) {
                        checked = $('#addToKlaviyoList').is(':checked');
                    }
                    $.ajax({
                        url: url,
                        type: 'get',
                        data: { customerEmailID: inputValue,
                            selectedPid: selectedPid,
                            checked: checked
                        },
                        success: function (data) {
                            $('.notify-error-message').addClass('d-none');
                            $('.oosNotifyMeEmail').val('');
                            $('#notifyMeModal').modal('hide');
                            $('#notifyMeThankYouModal').modal('show');
                            $('#addToKlaviyoList').prop('checked', false);
                        },
                        error: function (data) {
                            $('.notify-me-content').remove();
                        }
                    });
                } else {
                    $('.notify-error-message').removeClass('d-none');
                }
            }
        });
    },
    showAllColorsToggle: function () { //PAP-3149: Toggle funtion for color swatches
        $("body").on('click', '.see-all-colors', function (e) {
            var text = $(this).text() == $(this).attr('data-show-all') ? $(this).attr('data-hide-all') : $(this).attr('data-show-all');
            $(this).text(text)
            $('.attribute .swatches').toggleClass('d-flex');
            $(this).closest('.attribute').find('.swatches-holder').toggleClass('d-flex');
        });
    },

    showStickyA2C: function () {
        var offsetTop = $(".cart-and-ipay").offset().top + 50;

        $(window).scroll(function(){
        var scrollTop = $(window).scrollTop();
        if(scrollTop > offsetTop){
            $(".sticky-a2c-section").show();
        } else {
            $(".sticky-a2c-section").hide();
        }
        });
    },
    
    loadCollapsiblePDContent: function () {
        var pdContent1Url = $(".collapsible-pdContent1").data("url");
        if (pdContent1Url && pdContent1Url.length >1) {
            $.ajax({
                url: pdContent1Url,
                method: 'GET',
                success: function (data) {
                    $(".collapsible-pdContent1").html(data);
                }
            });
        }

        var pdContent2Url = $(".collapsible-pdContent2").data("url");
        if (pdContent2Url && pdContent2Url.length >1) {
            $.ajax({
                url: pdContent2Url,
                method: 'GET',
                success: function (data) {
                    $(".collapsible-pdContent2").html(data);
                }
            });
        }

        var pdContent3Url = $(".collapsible-pdContent3").data("url");
        if (pdContent3Url && pdContent3Url.length >1) {
            $.ajax({
                url: pdContent3Url,
                method: 'GET',
                success: function (data) {
                    $(".collapsible-pdContent3").html(data);
                }
            });
        }

        var pdContent4Url = $(".collapsible-pdContent4").data("url");
        if (pdContent4Url && pdContent4Url.length >1) {
            $.ajax({
                url: pdContent4Url,
                method: 'GET',
                success: function (data) {
                    $(".collapsible-pdContent4").html(data);
                }
            });
        }

        var pdContent5Url = $(".collapsible-pdContent5").data("url");
        if (pdContent5Url && pdContent5Url.length >1) {
            $.ajax({
                url: pdContent5Url,
                method: 'GET',
                success: function (data) {
                    $(".collapsible-pdContent5").html(data);
                }
            });
        }
    }
};