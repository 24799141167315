'use strict';

var tinySlider = require('tiny-slider/src/tiny-slider');
var zoom = require('jquery-zoom');
var imagesLoaded = require('imagesloaded');
require('jquery.scrollintoview');

var SiteConstants = require('constants/SiteConstants');
var mediumBreakpoint = SiteConstants.BreakpointSizes.md;
var largeBreakpoint = SiteConstants.BreakpointSizes.lg;

var abSlider = {};

// Check custom attributes on slider element to get settings, falling back to defaults if not populated
abSlider.getSliderAttributes = function($slider) {
    var attributes = new Object();

    // Autoplay (defaults to false)
    attributes.autoplayDelay = parseInt($slider.attr('data-slider-autoplay'));
    attributes.autoplay = isNaN(attributes.autoplayDelay) ? false : true;
    attributes.autoplayTimeout = attributes.autoplay ? attributes.autoplayDelay : 5000;

    // Loop (defaults to true)
    attributes.loop = $slider.attr('data-slider-loop') === 'false' ? false : true;
    // Swipe slider (defaults to false)
    attributes.scrollSlider = $slider.attr('data-slider-scrollslider') === 'true' ? true : false;
    
    // Number of items to show (defaults to 1 at all breakpoints)
    attributes.itemsNumber = parseInt($slider.attr('data-slider-items'));
    attributes.items = isNaN(attributes.itemsNumber) ? 1 : attributes.itemsNumber;
    attributes.itemsNumberMedium = parseInt($slider.attr('data-slider-items-md'));
    attributes.itemsMedium = isNaN(attributes.itemsNumberMedium) ? 1 : attributes.itemsNumberMedium;
    attributes.itemsNumberLarge = parseInt($slider.attr('data-slider-items-lg'));
    attributes.itemsLarge = isNaN(attributes.itemsNumberLarge) ? 1 : attributes.itemsNumberLarge;

    // Gutter space between items (defaults to 0px)
    attributes.gutter = parseInt($slider.attr('data-slider-gutter')) || 0;
    attributes.gutterMedium = parseInt($slider.attr('data-slider-gutter-md')) || 0;
    attributes.gutterLarge = parseInt($slider.attr('data-slider-gutter-lg')) || 0;

    // Slide animation speed (defaults to 300ms)
    attributes.speed = parseInt($slider.attr('data-slider-speed')) || 300;

    // Slide animation (defaults to standard Carousel behavior)
    attributes.mode = $slider.attr('data-slider-mode') || 'carousel';
    attributes.animateIn = $slider.attr('data-slider-animatein') || 'no-animation';
    attributes.animateOut = $slider.attr('data-slider-animateout') || 'no-animation';

    // Thumbnail settings (defaults to false)
    // If a selector is set for [data-slider-thumbnail container], the rest of the settings populate automatically
    var $thumbnailSlider = $($slider.attr('data-slider-thumbnail-container'));
    attributes.thumbnailContainer = $slider.attr('data-slider-thumbnail-container') || false;
    attributes.navContainer = $thumbnailSlider.length ? $thumbnailSlider.find('.slider')[0] : false;
    attributes.navAsThumbnails = attributes.navContainer === false ? false : true;
    attributes.controlsContainer = $thumbnailSlider.length ? $thumbnailSlider.find('.slider-thumbnail-controls')[0] : false;
    return attributes;
}

// Build parameter object to create a slider
abSlider.getSliderParams = function($slider, slideItems) {
    var attributes = abSlider.getSliderAttributes($slider);
    var params = {
        container: $slider.find('.slider')[0],
        loop: attributes.loop,
        scrollSlider: attributes.scrollSlider,
        items: attributes.items,
        autoplay: attributes.autoplay,
        autoplayTimeout: attributes.autoplayTimeout,
        autoplayHoverPause: true,
        mode: attributes.mode,
        gutter: attributes.gutter,
        speed: attributes.speed,
        navContainer: attributes.navContainer,
        navAsThumbnails: attributes.navAsThumbnails,
        controlsContainer: attributes.controlsContainer,
        preventScrollOnTouch: 'auto', // prevents mobile errors in chrome
        responsive: {
            [mediumBreakpoint]: {
                items: attributes.itemsMedium,
                gutter: attributes.gutterMedium,
            },
            [largeBreakpoint]: {
                items: attributes.itemsLarge,
                gutter: attributes.gutterLarge,
            }
        }
    }

    if (slideItems) {
        params.slideItems = slideItems;
    }

    if (params.mode === 'gallery') {
        params.animateIn = attributes.animateIn;
        params.animateOut = attributes.animateOut;
    }

    return params;
}

// Uses scrollintoview jQuery plugin (needed for IE11 support) to scroll the active thumbnail into view
// Note: Thumbnail container needs to be scrollable (overflow: auto) for this to work
abSlider.slideIntoView = function(slider) {
    if (slider.navContainer !== false && $(slider.navContainer).hasClass('slider')) {
        var $activeThumbnail = $(slider.navContainer).find('.tns-nav-active');
        $activeThumbnail.scrollintoview({viewPadding: { y: 40, x: 40}});
    }
    loadTns();
}

// Build out slide html for replacing images on variant selection
abSlider.getUpdatedSlideItems = function(images, isThumbnail) {
    var slideItems = [];

    images['large'].forEach((image, index) => {
        var bundleDetailData = $('.product-detail.bundle-item.custom-bundle').length ? $('.product-detail.bundle-item.custom-bundle').data() : null;
        var mixAndMatchData = $('.product-detail.mix-and-match-container').length ? $('.product-detail.mix-and-match-container').data() : null;
        var $slideElement = $('<div></div>').attr('class', 'slide');
        var $imageElement = $('<img/>');

        $imageElement.attr({
            'src': image.url, 
            'alt': image.alt + ' image number ' + (parseInt(image.index) + 1).toFixed(0),
            'class': 'd-block img-fluid base-image',
            'itemprop': 'image'
        });

        if (bundleDetailData || mixAndMatchData || ($('.byo-bundle').length > 0 && $('.byo-bundle').attr('showPopTopDesigns') === 'true')) {
            if ($('.byo-bundle').length > 0) {
                var bundleImage =  $('.byo-bundle').find('.pop-top-design button input.selected').attr('overlayimage');
                var lastImageOverlayIndex = '6.0';
                var isPoptivism = false;
                var customerUpload = false;
            } else if (mixAndMatchData) {
                var $mmCard = $('.product-detail.mix-and-match-container').find('.card-wrapper.mix-and-match-wrapper .collapse-area.show');
                var selectedCardType = $mmCard.closest('.card-wrapper.mix-and-match-wrapper').attr('data-attrvalue');
                if ((!selectedCardType && !$('#mix-and-match-3').length) || selectedCardType === 'mm-AdhesiveBaseWithTop') {
                    selectedCardType = 'mm-AdhesiveBaseWithTop';
                }
                if (selectedCardType === 'mm-AdhesiveBaseWithTop' || selectedCardType === 'mm-MagsafeBaseWithTop') {
                    if (index == 0 || index == 3 || index == 5) {
                        if (index == 0 && selectedCardType === 'mm-AdhesiveBaseWithTop') {
                            var bundleImage = mixAndMatchData.hasOwnProperty('fourthstyleimg') ? mixAndMatchData.fourthstyleimg : null;
                        } else {
                            var bundleImage = mixAndMatchData.hasOwnProperty('secondstyleimg') ? mixAndMatchData.secondstyleimg : null;
                        }
                    } else if (index == 1 || index == 2) {
                        var bundleImage = mixAndMatchData.hasOwnProperty('firststyleimg') ? mixAndMatchData.firststyleimg : null;
                    } else if (index == 4) {
                        var bundleImage = mixAndMatchData.hasOwnProperty('thirdstyleimg') ? mixAndMatchData.thirdstyleimg : null;
                    }
                    var lastImageOverlayIndex = mixAndMatchData.hasOwnProperty('lastimageoverlayindex') ? mixAndMatchData.lastimageoverlayindex : '6.0';
                }
            } else {
                var bundleImage = bundleDetailData.hasOwnProperty('bundleimage') ? bundleDetailData.bundleimage : null;
                var lastImageOverlayIndex = bundleDetailData.hasOwnProperty('lastimageoverlayindex') ? bundleDetailData.lastimageoverlayindex : '6.0';
                var isPoptivism = bundleDetailData.hasOwnProperty('isPoptivism') ? bundleDetailData.isPoptivism : false;
                var customerUpload = bundleDetailData.hasOwnProperty('customerUpload') ? bundleDetailData.customerUpload : false;
            }
            lastImageOverlayIndex = Number.parseFloat(lastImageOverlayIndex);

            if (bundleImage && ((index <= lastImageOverlayIndex && !customerUpload) || $('.pop-top-design').length > 0)) {
                var imgElementToAdd = document.createElement('img');
                imgElementToAdd.src = bundleImage;
                imgElementToAdd.classList.add('img-fluid', 'overlay-image');

                // add overlaid signifier for slide
                $slideElement.addClass('overlaid');

                if (isPoptivism) {
                    $slideElement.addClass('poptivism');
                }

                // add bundle image for overlay to all images within
                // the threshold defined in custom preference:
                // imageOverlayLastIndex
                $slideElement.append(imgElementToAdd);
            }
        }

        $slideElement.prepend($imageElement);
        slideItems.push($slideElement[0]);
    });

    return slideItems;
}

// Listen for the slider:update event and reinitialize slider with new slides
abSlider.bindSliderUpdateEvent = function($sliderContainer, slider) {
    $sliderContainer.off('slider:update').on('slider:update', '.slider', (event, data) => {
        var $updatedSliderContainer = $(event.target).closest('.slider-container');
        var isThumbnail = $updatedSliderContainer.hasClass('slider-thumbnails');
        var updatedSlideItems = data !== undefined ? abSlider.getUpdatedSlideItems(data.images, isThumbnail) : null;
        var updatedParams = abSlider.getSliderParams($updatedSliderContainer, updatedSlideItems);

        // Update carousel classes to handle number of images changing
        if (isThumbnail) {
            var $thumbnailContainer = $updatedSliderContainer.closest('.primary-images-thumbnails');
            var $mainImageContainer = $thumbnailContainer.prev('.primary-images-main');

            if (data.images.small && data.images.small.length > 1) {
                if ($(window).width() > 768) {
                    $thumbnailContainer.removeClass('d-none');
                    $mainImageContainer.addClass('product-has-thumbnails col-md-9');

                    if($('body').find('.product-detail.product-wrapper.mix-and-match').length > 0) {
                        $mainImageContainer.removeClass('col-lg-12');
                    }
                }
            } else {
                $thumbnailContainer.addClass('d-none');
                $mainImageContainer.removeClass('product-has-thumbnails col-md-9');

                if($('body').find('.product-detail.product-wrapper.mix-and-match').length > 0) {
                    $mainImageContainer.addClass('col-lg-12');
                }
            }
        }

        // Remove and re-add slider markup if there are new slides to show
        if (updatedParams.slideItems) {
            var $newSliderElement = $('<div></div>').attr({
                'class': 'slider',
                'id': updatedParams.container.id
            });
            var $newSliderControls = $updatedSliderContainer.find('.slider-thumbnail-controls').addClass('d-none');
            $newSliderElement.html(updatedParams.slideItems);
            $updatedSliderContainer.html($newSliderElement);
            updatedParams.container = $newSliderElement[0];

            // If this is a thumbnails slider, also add the arrow controls and update the item count
            if (isThumbnail) {
                $updatedSliderContainer.append($newSliderControls);
                updatedParams.items = updatedParams.slideItems.length;
                updatedParams.responsive[mediumBreakpoint].items = updatedParams.slideItems.length;
                updatedParams.responsive[largeBreakpoint].items = updatedParams.slideItems.length;
            }
        }

        // Reinitialize slider using new params
        slider = tinySlider.tns(updatedParams);
        slider.events.on('transitionEnd', abSlider.slideIntoView);

        if (isThumbnail) {
            abSlider.handleThumbnailArrows($updatedSliderContainer, slider);
        }

        if ($(window).width() < 768) {
            if(updatedParams.scrollSlider){
                slider.destroy();
                $sliderContainer.addClass('scroll-slider-active');
                new SimpleBar($('.primary-images-main .scroll-slider-active .slider')[0], { autoHide: false });
            }
        }
    });
}

// Determine whether to show thumbnail arrows based on overflow state
abSlider.handleThumbnailArrows = function($sliderContainer, slider) {
    if ($sliderContainer.hasClass('slider-thumbnails')) {
        var $slider = $sliderContainer.find('.slider');
        var $arrows = $sliderContainer.find('.slider-thumbnail-controls');
        var buffer = 5; // adding a buffer to prevent arrows from showing if the last slide is mostly visible
        var containerSize;
        var sliderSize;

        $slider.imagesLoaded().done(() => {
            if (window.isMobile()) {
                containerSize = $sliderContainer.outerWidth();
                sliderSize = $slider[0].scrollWidth;
            } else {
                containerSize = $sliderContainer.outerHeight();
                sliderSize = $slider[0].scrollHeight;
            }

            if (sliderSize - buffer >= containerSize) {
                $arrows.removeClass('d-none');
            }
        });
    }
}

// Check for videos and pause them when they're not in the active slide
abSlider.handleSliderVideos = function($sliderContainer, slider) {
    var $videos = $sliderContainer.find('.hero-media.video-container, .experience-commerce_assets-video>.video-container');

    if ($videos.length) {
        slider.events.on('indexChanged', activeSlider => {
            $videos.each((_i, element) => {
                var $videoContainer = $(element);
                var videoID = $videoContainer.find('.video-player').attr('id');
                var player = window.Players[videoID];

                if (player) {
                    if (!$videoContainer.closest('.tns-slide-active').length) {
                        player.pause();
                    } else if (player.config.autoplay) {
                        player.play();
                    }
                }
            });
        });
    }
}

// Handle Pause/Play Button
abSlider.bindPauseButton = function($sliderContainer, slider) {
    var sliderButtonPlacement = $sliderContainer.attr('data-slider-pause-button-placement');

    if ($sliderContainer[0].hasAttribute('data-slider-autoplay') && $sliderContainer.attr('data-slider-autoplay') != 'false' && $sliderContainer[0].hasAttribute('data-slider-pause-button-placement') && $sliderContainer.attr('data-slider-pause-button-placement') != "none")  {
        if (sliderButtonPlacement == 'with-pagination') {
            $sliderContainer.find('.tns-nav').addClass('with-pagination-btn').append('<a href="#" class="tns-nav-controller"><span class="sr-only">stop and start slider</span></a>');
        } else {
            $sliderContainer.prepend('<a href="#" class="tns-nav-controller"><span class="sr-only">stop and start slider</span></a>');
        };

        $('.tns-nav-controller').addClass(sliderButtonPlacement);

        $sliderContainer.on('click', '.tns-nav-controller', function() {
            event.preventDefault();
            $(this).toggleClass('pause');
            $sliderContainer.toggleClass('slider-pause');

            if ($sliderContainer.hasClass('slider-pause')) {
                slider.pause();
            } else {
                slider.play();
            }
        });
    }
};

abSlider.initializeSliders = function($context = $('body')) {
    var scope = this;
    // delay-init is different from replace-content
    var $sliderContainers = $context.find('.slider-container:not(.delay-init):not(.slider-initiated)');

    $sliderContainers.each((i, slider) => {
        var $sliderContainer = $(slider);
        var $closestTabPane = $sliderContainer.closest('.tab-pane.fade');
        if ($closestTabPane.length > 0 && (!$closestTabPane.is('.active') || ($closestTabPane.is('.active') && $sliderContainer.find('div.tns-outer').length > 0))) {
        } else {
            var params = scope.getSliderParams($sliderContainer);
            var promisegroup = [];
            var $replace = $sliderContainer.find('[data-replace-content]');
    
            $replace.each(function (i, replace) {
                var $target = $(replace);
                var deferment = $target.data('deferment');
    
                if (deferment && !deferment.isResolved && deferment.state() === 'pending') {
                    promisegroup.push(deferment);
                }
            });
    
            // Done will also execute if promisegroup is empty []
            $.when.apply($, promisegroup).done(function () {
                scope.applyTinySlider($sliderContainer, params);
            });
        }
    });
    if ($('.nav.tabpicker .nav-item').length > 0) {
        $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
            var activeTabId = $(e.target).attr('href');
            window.location.hash = activeTabId;
        });

        if (window.location.hash) {
            var hash = window.location.hash;
            var $targetTab = $('a[href="' + hash + '"]');
            if ($targetTab.length) {
                $targetTab.tab('show');
            }
        }
    }
}

abSlider.applyTinySlider = function ($container, params) {
    if (!$container) return;

    // PAP-2402: Canada HP Carousel - remove Anker MagGo
    var $slides = $container.find('.slide');
    $slides.each(function(i) {
        var price = $(this).find('.price').text().trim();
        var isNullPrice = (price.trim() === "null") ? true : false;
        if(isNullPrice === true) {
            $slides[i].remove();
        }
    });    
    
    var tslider = tinySlider.tns(params);

    if (!!tslider) {
        tslider.events.on('transitionStart', this.slideIntoView);

        this.bindPauseButton($container, tslider);
        this.bindSliderUpdateEvent($container, tslider);
        this.handleThumbnailArrows($container, tslider);
        this.handleSliderVideos($container, tslider);

        $('body').trigger('slider:init.sliderEvents', [$container]);
        $container.addClass('slider-initiated');

        if ($(window).width() < 768) {
            if(params.scrollSlider){
                tslider.destroy();
                $container.addClass('scroll-slider-active');
                if($container.hasClass('pdp-slider-custom')) {
                    new SimpleBar($('.primary-images-main .pdp-slider-custom.scroll-slider-active .slider')[0], { autoHide: false });
                }
            }
        }
    }
    $container.trigger('tooltip:init');
}

abSlider.initializeQuickviewModalSliders = function() {
    $('body').off('quickview:ready').on('quickview:ready', (event, modal) => {
        abSlider.initializeSliders($(modal));
    });
}

abSlider.initializeWishlistModalSliders = function() {
    $('body').off('editwishlistproduct:ready').on('editwishlistproduct:ready', (event, modal) => {
        abSlider.initializeSliders($(modal));
    });
}

abSlider.initializeTargetSliders = function() {
    $('body').off('targetslider:ready').on('targetslider:ready', (event, target) => {
        abSlider.initializeSliders($(target));
    });
}

abSlider.initializeTabSliders = function() {
    $(document).off('click','div.tabs-holder a.nav-link');
    $(document).on('click', 'div.tabs-holder a.nav-link', function(e) {
        initializeTabSlidersTimeout($(e.target));
    });
}

function initializeTabSlidersTimeout($target) {
    setTimeout(function() {
        var tabContainerID = $target.attr('href').replace('#', '');
        var $targetContainer = $('div#' + tabContainerID);
        if (!$targetContainer.is('.active')) {
            initializeTabSlidersTimeout($target);
        } else {
            abSlider.initializeSliders($('div#' + tabContainerID));
        }
    }, 10);
}
$(document).on("click", ".experience-commerce_assets-tabs .nav-item", function(e) {
    e.preventDefault();
    var img_index = $(this);

    $('.nav-wrapper').scrollLeft($(img_index).offset().left - $(img_index).parent().offset().left);

});
function loadTns() {
    if ($('.tns-controls button').length) {
        for (var i = 0; i < $('.tns-controls button').length; i++) {
            if ($('.tns-controls button')[i].innerHTML === 'prev') {
                $('.tns-controls button')[i].ariaLabel = 'Prev Arrow';
            } else {
                $('.tns-controls button')[i].ariaLabel = 'Next Arrow';
            }
            $('.tns-controls button')[i].tabIndex = 0;
        }
    }
    var thumbnailDivs = $('.thumbnail-images-focus')
    if (thumbnailDivs.length > 0) {
        for (i = 0; i < thumbnailDivs.length; i++) {
            thumbnailDivs[i].tabIndex = 0;
        }
    }

    const tnsDotItems = document.querySelectorAll('.tns-nav button');
    if (tnsDotItems.length) {
        [].forEach.call(tnsDotItems, function (e) {
            e.setAttribute('tabIndex', '0');
        });
    }

    const tnsControls = document.querySelectorAll('.tns-controls');
    if (tnsControls.length) {
        [].forEach.call(tnsControls, function (e) {
            e.removeAttribute('tabIndex');
        });
    }
    $('.lazy').Lazy();
    $(".slider-container .video-player").each(function(){
        var attrID = $(this).attr("id");
        if($(this).attr("data-video-id") !== "null") {
            var videoPlayer = new Vimeo.Player(attrID, HTMLOptionsCollection);
            videoPlayer.play(); 
        }
    });

    $(document)
        .on("touchstart", ".primary-images-main .slider", function(e) {
            $('.primary-images-thumbnails .slide.tns-slide-active').css('background', '#D4D4D4');
        })
        .on("touchend", ".primary-images-main .slider", function(e) {
            setTimeout(function() {
                $('.primary-images-thumbnails .slide.tns-slide-active').css('background', '#FFF');
            }, 600);
        });
}


abSlider.init =  function() {
    abSlider.initializeSliders();
    abSlider.initializeQuickviewModalSliders();
    abSlider.initializeWishlistModalSliders();
    abSlider.initializeTargetSliders();
    abSlider.initializeTabSliders();
}

module.exports = abSlider;